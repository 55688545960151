<template>
  <div class="resetbind-view">
    <div class="resetbind-wrap">
      <div class="resetbind-title">重置绑定号码</div>
      <el-form class="resetbind-form" label-width="130px" label-position="left">
        <template v-if="formStep == 1">
          <div style="margin-top: 64px">请填写以下公司信息</div>
          <el-form-item label="公司名称" style="margin-top: 51px">
            <el-input
              v-model="form.name"
              placeholder="请填写公司名称"
              clearable
            ></el-input>
          </el-form-item>
          <el-form-item label="公司代码">
            <el-input
              v-model="form.code"
              placeholder="请填写公司代码"
              clearable
            ></el-input>
          </el-form-item>
          <el-form-item label="新绑定手机号码">
            <el-input
              v-model="form.phone"
              placeholder="请填写新绑定手机号码"
              clearable
            ></el-input>
          </el-form-item>
          <div class="reset-tip">重置成功后，密码将发送到新的手机号码</div>
          <div class="warning-tip" style="margin-top: 66px">经办人信息</div>
          <el-form-item label="经办人姓名" style="margin-top: 35px">
            <el-input
              v-model="form.operater"
              placeholder="请填写经办人姓名"
              clearable
            ></el-input>
          </el-form-item>
          <el-form-item label="经办人邮箱">
            <el-input
              v-model="form.mailOp"
              placeholder="请填写经办人邮箱"
              clearable
            ></el-input>
          </el-form-item>
        </template>

        <template v-if="formStep == 2">
          <input ref="upload" type="file" @change="onSelectFile" hidden />
          <div style="margin-top: 64px">请完善公司信息</div>
          <el-form-item label="经办人身份证" style="margin-top: 48px">
            <div class="idcard idcard1" @click="selectFile('idCardF')">
              <img v-if="files.idCardF.blob" :src="files.idCardF.blob" alt="" />
            </div>
            <div class="idcard idcard2" @click="selectFile('idCardF2')">
              <img
                v-if="files.idCardF2.blob"
                :src="files.idCardF2.blob"
                alt=""
              />
            </div>
          </el-form-item>
          <el-form-item label="授权文件">
            <div class="auth-file" @click="selectFile('certF')">
              <i class="el-icon-plus"></i>
              <span>授权书</span>
              <img
                class="auth-image"
                v-if="files.certF.blob"
                :src="files.certF.blob"
                alt=""
              />
            </div>
          </el-form-item>
        </template>

        <div class="action-btn-row">
          <el-button
            class="action-btn"
            type="primary"
            :disabled="formStep == 1"
            @click="formStep--"
            >上一步</el-button
          >
          <el-button
            class="action-btn"
            type="primary"
            v-if="formStep == 1"
            @click="formStep++"
            >下一步</el-button
          >
          <el-button
            class="action-btn"
            type="primary"
            v-if="formStep == 2"
            @click="resetNumber"
            >提交</el-button
          >
        </div>
      </el-form>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      form: {
        name: "",
        code: "",
        phone: "",
        operater: "",
        mailOp: "",
        idCardF: "",
        idCardF2: "",
        certF: "",
      },

      uploadFileType: "",

      files: {
        idCardF: {
          file: null,
          blob: null,
        },
        idCardF2: {
          file: null,
          blob: null,
        },
        certF: {
          file: null,
          blob: null,
        },
      },

      formStep: 1,
    };
  },
  methods: {
    selectFile(key) {
      this.selectFileType = key;
      this.$refs.upload.click();
    },
    onSelectFile(e) {
      let files = e.target.files;
      if (files.length == 0) return;

      this.files[this.selectFileType].file = files[0];
      this.files[this.selectFileType].blob = URL.createObjectURL(files[0]);

      e.target.value = "";
    },
    resetNumber() {
      if (this.form.name == "") {
        alert("请输入公司名称");
        return;
      }
      if (this.form.code == "") {
        alert("请输入公司代码");
        return;
      }
      if (this.form.phone == "") {
        alert("请输入新绑定手机号码");
        return;
      }

      if (this.form.operater == "") {
        alert("请输入经办人姓名");
        return;
      }
      if (this.form.mailOp == "") {
        alert("请输入经办人邮箱");
        return;
      }

      if (!this.files.idCardF.files) {
        alert("请选择身份证正面图片");
        return;
      }
      if (!this.files.idCardF2.files) {
        alert("请选择身份证反面图片");
        return;
      }
      if (!this.files.certF.files) {
        alert("请需选择授权书");
        return;
      }
      let data = {
        name: this.form.name,
        code: this.form.code,
        phone: this.form.phone,
        operater: this.form.operater,
        mailOp: this.form.mailOp,
        idCardF: this.files.idCardF.file,
        idCardF2: this.files.idCardF2.file,
        certF: this.files.certF.file,
      };
      var formdata = new FormData();
      Object.keys(data).forEach((key) => {
        formdata.set(key, data[key]);
      });

      this.$axios.post("/member/resetNumber", formdata).then((res) => {
        if (res.state == 200) {
          alert(res.message);
        } else {
          alert(res.message);
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.resetbind-view {
  background: #f9f9f9;
  padding: 182px 0 114px 0;
  .resetbind-wrap {
    width: 1050px;
    // height: 777px;
    background: #ffffff;
    margin: 0 auto;
    padding: 64px 0;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    // justify-content: center;
    align-items: center;
    flex-shrink: 0;
    .resetbind-title {
      height: 40px;
      font-size: 28px;
      color: #333333;
      line-height: 40px;
      letter-spacing: 1px;
    }
    .resetbind-form {
      width: 500px;
    }
    .reset-tip {
      font-size: 16px;
      color: #3883ff;
      line-height: 22px;
      text-align: right;
    }
    .warning-tip {
      font-size: 20px;
      color: #fa6400;
      line-height: 28px;
    }
    .action-btn-row {
      display: flex;
      justify-content: center;
      margin-top: 70px;
      .action-btn {
        width: 151px;
      }
    }

    .idcard {
      width: 240px;
      height: 151px;
      background: #ffffff;
      border-radius: 4px;
      border: 2px solid #e6ebf4;
      cursor: pointer;
      img {
        width: 100%;
        height: 100%;
      }
    }
    .idcard1 {
    }
    .idcard2 {
      margin-top: 24px;
    }
    .auth-file {
      width: 206px;
      height: 240px;
      background: #ffffff;
      border: 1px solid #dadada;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      position: relative;
      .auth-image {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }
    }
  }
}
</style>